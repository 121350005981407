//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TrackOrderIcon from '~/static/svg/track-order/track-your-order-icon-home-fa.svg';
import UserIcon from '~/static/svg/user-blue.svg';
import TradeIcon from '~/static/svg/trade-blue.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    TrackOrderIcon,
    UserIcon,
    TradeIcon,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      orderId: null,
      canShowDesktopBanner: true,
      canShowMobileImage: false,
    };
  },

  computed: {
    ...mapGetters({
      getIsLoggedIn: 'users/getIsLoggedIn',
    }),
    isBannerEnabled() {
      return this.enabled;
    },

    isApp() {
      if (window.navigator.standalone || this.isRunningStandalone()) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async submitTrackOrder() {
      const result = await this.$refs.validator.validate();
      if (!this.orderId) return 'enter an order id';
      if (!result) return;

      this.$router.push({
        path: `/track-order/all/${this.orderId}/`,
      });
    },
    isRunningStandalone() {
      return window.matchMedia('(display-mode: standalone)').matches;
    },
    updateOrderNumber(inputObject) {
      this.orderId = inputObject.value;
    },
  },
};
