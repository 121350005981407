import { render, staticRenderFns } from "./TrackMyOrder.vue?vue&type=template&id=45a16c12&scoped=true&"
import script from "./TrackMyOrder.vue?vue&type=script&lang=js&"
export * from "./TrackMyOrder.vue?vue&type=script&lang=js&"
import style0 from "./TrackMyOrder.vue?vue&type=style&index=0&id=45a16c12&lang=scss&scoped=true&"
import style1 from "./TrackMyOrder.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a16c12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiText: require('/home/node/frontend/components/form/UiText.vue').default,BdButton: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/BdButton.vue').default,Card: require('/home/node/frontend/components/includes/Card.vue').default})
